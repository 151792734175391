import axios from "axios";
import { Request } from "../../../request";
import Cookies from 'js-cookie';

interface CustomError {
    message: string;
    errorCode: string;
}

export interface History {
    push: (location: string) => void;
}

export interface Response<T> {
    data: T | null;
    status: number | null;
    error: CustomError | null;
}

function request<T>({ method, endpoint, body, headers={} }: Request, history: History): Promise<Response<T>> {
    const result: Response<T> = {
        data: null,
        error: null,
        status: null
    };
    const axiosConfig = Object.keys(headers).length ? {
        url: endpoint,
        method,
        data: body,
        headers
    } : {
        url: endpoint,
        method,
        data: body,
    }
    return axios(axiosConfig).then(res => {
        result.data = res.data;
        result.status = res.status;
        return result;
    }).catch(e => {
        if (e.response.status === 401 && !endpoint.includes('auth')) {
            localStorage.clear();
            Cookies.remove('amb-auth-token');
            history.push("/login");
            localStorage.setItem("session", "expired");
            return result;
        } else {
            result.error = e.response.data;
            return result;
        }
    });
}

export {
    request,
}