import * as React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { SessionContext } from "./";
import { breakPoints } from "consts";
import { useIntl } from "react-intl";
import * as themeColors from '../../colors';
import { CopyText } from "consts/copyText";

const LinksContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 3;
    @media (max-width: ${breakPoints.l}) {
        font-size: 1em;
    }
    font-size: 16px;
    margin-top: 5%;
`;

const activeStyle = {
    color: themeColors.warningBase,
    backgroundColor: themeColors.corePrimary3
};

const LinkText = styled.span`
    font-size: 1em;
    margin-left: 5%;
`;

const LinkRow = styled(NavLink)`
    margin-top: 5px;
    width: 100%;
    display: flex;
    height: 40px;
    color: ${props => props.theme.colors.corePrimary5};
    align-items: center;
    :hover {
       color: ${props => props.theme.colors.white};
       background-color: rgba(55, 123, 252, 0.3);
    }
`;

const WarningContainer = styled.div`
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconContainer = styled.div`
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const LinkTextContainer = styled.div`
    flex: 1;
    display: flex;
`;

const WarningIndicator = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.errorColor};
`;

interface Props {
    hideMenu: () => void;
}

const Nav = (props: Props) => {
    const intl = useIntl();
    const context = React.useContext(SessionContext);
    let userWarning;
    if (context.profileUpdateRequired) {
        userWarning = (
            <WarningIndicator />
        );
    }
    let w8Warning;
    let w8Link;
    if (context.w8VerificationRequired) {
        w8Warning = (
            <WarningIndicator />
        );

        w8Link = (
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} to="/w8">
                <WarningContainer>
                    {w8Warning}
                </WarningContainer>
                <IconContainer>
                    <Icon name="file pdf" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({ id: "sidebar.navLinks.w8", defaultMessage: "Form W8" })}</LinkText>
                </LinkTextContainer>
            </LinkRow>
        );
    }

    return (
        <LinksContainer>
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} exact to="/">
                <WarningContainer />
                <IconContainer>
                    <Icon name="table" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({ id: "sidebar.navLinks.dashboard", defaultMessage: "Dashboard" })}</LinkText>
                </LinkTextContainer>
            </LinkRow>
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} to="/user-info">
                <WarningContainer>
                    {userWarning}
                </WarningContainer>
                <IconContainer>
                    <Icon name="user" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({ id: "sidebar.navLinks.userInfo", defaultMessage: "User Information" })}</LinkText>
                </LinkTextContainer>
            </LinkRow>
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} to="/statements">
                <WarningContainer />
                <IconContainer>
                    <Icon name="file alternate" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({ id: "sidebar.navLinks.statements", defaultMessage: "Statements" })}</LinkText>
                </LinkTextContainer>
            </LinkRow>
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} to="/trade-confirms">
                <WarningContainer />
                <IconContainer>
                    <Icon name="tasks" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({ id: "sidebar.navLinks.tradeConfirms", defaultMessage: "Trade Confirms" })}</LinkText>
                </LinkTextContainer>
            </LinkRow>
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} to="/tax-forms">
                <WarningContainer />
                <IconContainer>
                    <Icon name="folder open" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({id: "sidebar.navLinks.taxForms", defaultMessage: "Tax Forms"})}</LinkText>
                </LinkTextContainer>
            </LinkRow>
            {w8Link}
            <LinkRow
                as='a'
                target="drivewealth-zendesk-help" 
                href="/api/zendesk/login"
                onClick={() => window.open('/api/zendesk/login','drivewealth-zendesk-help','width=800,height=980')}
            >
                <WarningContainer />
                <IconContainer>
                    <Icon name="question circle" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({id: "sidebar.navLinks.help", defaultMessage: "Help"})}</LinkText>
                </LinkTextContainer>
            </LinkRow>
            <LinkRow onClick={props.hideMenu} activeStyle={activeStyle} to="/disclosure-regulatory">
                <WarningContainer />
                <IconContainer>
                    <Icon name="folder outline" />
                </IconContainer>
                <LinkTextContainer>
                    <LinkText>{intl.formatMessage({ id: "disclosure.header.title", defaultMessage: CopyText.disclosuresPage.pageTitle })}</LinkText>
                </LinkTextContainer>
            </LinkRow>
        </LinksContainer>
    );
};

export default Nav;
