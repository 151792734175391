import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./index.css";
import 'semantic-ui-css/semantic.min.css'

const language = navigator.language;
import(`./i18n/${language}.json`).then(appCopy => {
    ReactDOM.render(
        <IntlProvider
            locale={language}
            messages={appCopy.default}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </IntlProvider>
        , document.getElementById('root'));
}).catch(() => {
    ReactDOM.render(
        <IntlProvider
            locale="en-US"
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </IntlProvider>
        , document.getElementById('root'));
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
