const breakPoints = {
    ipadPro: "1024px",
    ipad: "768px",
    smallMobile: "320px",
    laptop: "1200px",
    l: "",
    m: "",
    s: "",
    medium: "",
    small: "", 
    phoneMax: "max-width: 600px",
    tabletPortraitMax: "max-width: 900px",
    tabletLandscapeMax: "max-width: 1200px",
    desktopMax: "max-width: 1800px",
}

export {
    breakPoints
};