import { request, History, Response } from "./request";

interface Document {
    displayName: string;
    fileKey: string;
}

export interface Statements {
    statements: Array<Document>;
}

export interface TradeConfirms {
    confirms: Array<Document>;
}

export interface TaxForms {
    taxForms: Array<Document>;
}

export interface DocumentsService {
    getStatements: (from: string, to: string, accountID: string) => Promise<Response<Statements>>;
    getTradeConfirms: (from: string, to: string, accountID: string) => Promise<Response<TradeConfirms>>;
    getTaxForms: (from: string, to: string, accountID: string) => Promise<Response<TaxForms>>;
    getFileUrl: (type: string, filekey: string, accountID: string) => Promise<Response<{url: Location}>>;
    getW8: () => Promise<Response<any>>;
    verifyW8: (documentID: string) => Promise<Response<{}>>;
    uploadDocument: (document: any) => Promise<Response<{}>>
}

function documentsFactory(h: History): DocumentsService {
    const docs: DocumentsService = {
        getStatements(from: string, to: string, accountID: string) {
            return request<Statements>({
                method: "get",
                endpoint: `/api/documents/statements/${accountID}?from=${from}&to=${to}`,
            }, h)
        },
        getTradeConfirms(from: string, to: string, accountID: string) {
            return request<TradeConfirms>({
                method: "get",
                endpoint: `/api/documents/confirms/${accountID}?from=${from}&to=${to}`,
            }, h)
        },
        getTaxForms(from: string, to: string, accountID: string) {
            return request<TaxForms>({
                method: "get",
                endpoint: `/api/documents/taxforms/${accountID}?from=${from}&to=${to}`
            }, h)
        },
        getFileUrl(type: string, filekey: string, accountID: string) {
            return request<{url: Location}>({
                method: "get",
                endpoint: `/api/documents/url/${type}/${accountID}/${filekey}`,
            }, h)
        },
        getW8() {
            return request<any>({
                method: "get",
                endpoint: "/api/documents/w8",
            }, h)
        },
        verifyW8(documentID: string) {
            return request<{}>({
                method: "post",
                body: {
                    documentID,
                },
                endpoint: "/api/documents/verify",
            }, h)
        },
        uploadDocument(document: any) {
            const body = new FormData();
            body.append('documentImage', document);
            
            return request<{}>({
                method: "post",
                body,
                endpoint: "/api/documents/upload",
                headers: {'content-type': 'multipart/form-data'}
            }, h)
        }
    }
    return docs;
}

export {
   documentsFactory,
}

