import * as React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

const ProtectedRoute = (props: RouteProps) => {
    const token = Cookies.get('amb-auth-token');
    if (token) {
        return (
            <Route
                component={props.component}
                path={props.path}
                exact={props.exact}
            />
        );
    }
    return (
        <Redirect
            to={{
                pathname: "/login",
            }}
        />
    );
};


export default ProtectedRoute;
