import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { breakPoints } from "consts";

const Logo = styled.img`
    @media (${breakPoints.tabletPortraitMax}) {
        width: 80%;
    }
    width: 100%;
    height: auto;
`;

const LogoLink = styled(Link)`
    width: 65%;
    margin-bottom: 16px;
`;

const Container = styled.div`
    width: 90%;
    margin-left: 10%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 5%;
    flex: 1;
    @media (max-width: ${breakPoints.l}) {
        justify-content: space-between;
    }
`;

const XContainer = styled.button`
    color: white;
    font-size: 16px;
    background-color: transparent;
    margin-right: 50px;
    border: none;
    cursor: pointer;
    outline: none;
    display: none;
    @media (${breakPoints.tabletLandscapeMax}) {
        display: inline;
    }
`;

interface Props {
    hideMenu: () => void;
}

const LogoComponent = (props: Props) => {
    return (
        <Container>
            <LogoLink to="/">
                <Logo src="drivewealth-logo.svg" />
            </LogoLink>
            <XContainer onClick={props.hideMenu}>
               <Icon name="close" />
            </XContainer>
        </Container>
    );
};

export default LogoComponent;
