import { History } from "./request";
import { authFactory } from "./auth";
import { countriesFactory } from "./countries";
import { documentsFactory } from "./documents";
import { usersFactory } from "./users";
import { Services, IndexSig } from "./types";

const services = {} as Services & IndexSig;

function servicesFactory(history: History) {
    const auth = authFactory(history);
    const countries = countriesFactory(history);
    const docs = documentsFactory(history);
    const users = usersFactory(history);
    const local: IndexSig = {
        ...auth,
        ...countries,
        ...docs,
        ...users,
    };
    Object.keys(local).forEach(key => {
        services[key] = local[key];
    });

}

export {
    servicesFactory,
    services,
}