import { request, History, Response } from "./request";
import Cookies from 'js-cookie';

export interface IdentifyBody {
    lastFour: string,
    dob: string,
    accountNo: string,
    email: boolean,
}

export interface AuthResponse {
    token: string;
}

type GetUserAccountInfoParams = {
    email: string;
    partnerIBID: string;
}

type VerifyUserParams = {
    otp: string;
    zendesk: boolean;
}

export interface AuthService {
    identifyUser: (body: IdentifyBody) => Promise<Response<{}>>;
    verifyUser: (params: VerifyUserParams) => Promise<Response<AuthResponse>>;
    logout: () => void;
    getUserAccountInfo: (body: GetUserAccountInfoParams) => Promise<Response<{}>>;
}

function authFactory(h: History): AuthService {
    const auth: AuthService = {
        identifyUser(body) {
            return request<{}>({
                method: "post",
                endpoint: "/api/auth/identify",
                body,
            }, h)
        },
        verifyUser({ otp, zendesk }) {
            return request<AuthResponse>({
                method: "post",
                endpoint: "/api/auth/verify",
                body: {
                    otp,
                    zendesk
                }
            }, h)
        },
        logout() {
            localStorage.clear();
            Cookies.remove('amb-auth-token');
        },
        getUserAccountInfo({ email, partnerIBID }) {
            const body = partnerIBID ? { email, partnerIBID } : { email };
            return request<{}>({
                method: "post",
                body,
                endpoint: "/api/auth/get-account",
            }, h)
        }
    }

    return auth;
}

export {
    authFactory,
}

