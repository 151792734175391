import { request, History, Response } from "./request";

export interface Country {
    id: string;
    name: string;
    code2: string;
    code3: string;
    active: boolean;
    taxTreatyCountry: boolean;
}

export type Countries = {
    countries: Array<Country>;
}

export interface CountriesService {
    getCountries: () => Promise<Response<Countries>>;
}

function countriesFactory(h: History): CountriesService {
    const countries: CountriesService = {
        getCountries() {
            return request<Countries>({
                method: "get",
                endpoint: "/api/countries",
            }, h)
        }
    }
    return countries;
}

export {
    countriesFactory,
}

