import { DefaultTheme } from "styled-components";
import * as themeColors from './colors';
import '@fontsource/space-grotesk';
const SPACE_GROTESK = 'Space Grotesk';

const {
    white,
    primaryBase,
    primary1,
    primary2,
    primary3,
    primary4,
    brandBase,
    positiveBase,
    positive1,
    positive2,
    positive3,
    positive4,
    infoBase,
    info1,
    info2,
    info3,
    info4,
    warningBase,
    warning1,
    warning2,
    warning3,
    warning4,
    negativeBase,
    negative1,
    negative2,
    negative3,
    negative4,
    grapeBase,
    grape1,
    grape2,
    grape3,
    grape4,
    ceriseBase,
    cerise1,
    cerise2,
    cerise3,
    cerise4,
    corePrimaryBase,
    corePrimary1,
    corePrimary4,
    corePrimary5,
  } = themeColors;

export const theme: DefaultTheme = {
    screenBackground: "white",
    contentBackground: "#f8f8f8",
    borderColor: "#efefef",
    linkColor: "#174962",
    linkHover: "#164861",
    linkActive: "#d19f02",
    darkTextColor: "#173243",
    lightTextColor: "#69797c",
    errorColor: "#e4c478",
    notificationCount: "#fdfafa",
    shadow: "#a6b2b7",
    buttonPrimary: "#2b88c2",
    primaryBtnText: "#f8fbfd",
    secondaryButton: "#7f7f81",
    warningBackground: "#fffaea",
    lightestTextColor: "#fafafa",
    darkGradient: "#113543",
    lightGradient: "#0F5978",
    warningBorder: "#f8efd4",
    lighterText: "#77868d",
    palette: {
        primary: {
        main: primaryBase,
        light: primary4,
        dark: primary1,
        contrastText: white,
        },
        secondary: {
        main: brandBase,
        contrastText: primaryBase,
        },
        success: {
        main: positiveBase,
        dark: positive1,
        light: positive4,
        contrastText: primaryBase,
        },
        info: {
        main: infoBase,
        dark: info1,
        light: info4,
        contrastText: primaryBase,
        },
        warning: {
        main: warningBase,
        dark: warning1,
        light: warning4,
        contrastText: primaryBase,
        },
        error: {
        main: negativeBase,
        dark: negative1,
        light: negative4,
        contrastText: white,
        },
    },
    typography: {
        fontFamily: SPACE_GROTESK,
        h1: {
        fontFamily: SPACE_GROTESK,
        fontSize: 40,
        lineHeight: '48px',
        letterSpacing: '0.1px',
        color: white,
        },
        h2: {
        fontFamily: SPACE_GROTESK,
        fontSize: 33,
        lineHeight: '40px',
        letterSpacing: '0.1px',
        color: white,
        },
        h3: {
        fontFamily: SPACE_GROTESK,
        fontSize: 28,
        lineHeight: '36px',
        letterSpacing: '0.1px',
        color: white,
        },
        h4: {
        fontFamily: SPACE_GROTESK,
        fontSize: 23,
        lineHeight: '32px',
        letterSpacing: '0.1px',
        color: white,
        },
        h5: {
        fontFamily: SPACE_GROTESK,
        fontSize: 19,
        lineHeight: '28px',
        letterSpacing: '0.1px',
        color: white,
        },
        body1: {
        fontFamily: SPACE_GROTESK,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: white,
        },
        body2: {
        fontFamily: SPACE_GROTESK,
        fontSize: 13,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        color: white,
        },
        subtitle1: {
        fontFamily: SPACE_GROTESK,
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: '0.1px',
        color: white,
        },
        subtitle2: {
        fontFamily: SPACE_GROTESK,
        fontSize: 10,
        textTransform: 'uppercase',
        lineHeight: '12px',
        letterSpacing: '0.1px',
        color: white,
        },
    },
    overrides: {},
    dw: {
        palette: {
            primary: {
                main: primaryBase,
                darkest: primary1,
                dark: primary2,
                light: primary3,
                lightest: primary4,
            },
            secondary: {
                main: '#F1B924',
            },
            success: {
                main: positiveBase,
                darkest: positive1,
                dark: positive2,
                light: positive3,
                lightest: positive4,
            },
            info: {
                main: infoBase,
                darkest: info1,
                dark: info2,
                light: info3,
                lightest: info4,
            },
            warning: {
                main: warningBase,
                darkest: warning1,
                dark: warning2,
                light: warning3,
                lightest: warning4,
            },
            error: {
                main: negativeBase,
                darkest: negative1,
                dark: negative2,
                light: negative3,
                lightest: negative4,
            },
            grape: {
                main: grapeBase,
                darkest: grape1,
                dark: grape2,
                light: grape3,
                lightest: grape4,
            },
            cerise: {
                main: ceriseBase,
                darkest: cerise1,
                dark: cerise2,
                light: cerise3,
                lightest: cerise4,
            },
            corePrimary: {
                main: corePrimaryBase,
                darkest: corePrimaryBase,
                dark: corePrimary1,
                light: corePrimary4,
                lightest: corePrimary5,
            },
        },
    },
    colors: {
        ...themeColors,
    }, 
  };