export const CopyText = {
    close: 'Close',
    downloadDocument: 'Download document',
    downloadNewDocument: 'Download your new tax document',
    fileTypeErrorMessage: 'Please upload a supported file type.',
    maxImageSizeErrorMessage: 'The max upload size is 10MB, please upload a smaller file.',
    statementsSearchPrompt: 'Use the date picker above to view your monthly statements',
    tradeConfirmsSearchPrompt: 'Use the date picker above to view your trade confirms',
    taxCenter: 'DriveClient',
    taxFormsSearchPrompt: 'Use the date picker above to view your tax forms',
    disclosuresPage: {
        pageTitle: 'Disclosures & Regulatory Documents',
        linkTileDescription: 'View all disclosures and policies',
        customerAgreementsTitle: 'Customer Agreements',
        tollFreeSubtext: 'Toll-free: ',
        telephoneNumberLink: 'tel:18004612680',
        telephoneNumberLabel: '+1 800 461 2680',
        USAAndCanada: 'USA & Canada',
        customerAccountAgreement: 'Customer Account Agreement',
        customerAccountAgreementMargin: 'Customer Account Agreement - Margin',
        alternativeInvestmentAgreement: 'Alternative Investments Agreement',
        disclosuresAndDisclaimers: 'Disclosures & Disclaimers',
        investmentProducts: 'Investment Products',
        tradingFeeSchedule: 'Trading Fee Schedule',
        tradingDisclosure: 'Trading Disclosure',
        baskets: 'Baskets',
        margin: 'Margin',
        dayTradingRisk: 'Day-Trading Risk',
        ADRFees: 'ADR Fees',
        SECRule606: 'SEC Rule 606 Disclosure (DriveWealth, LLC)',
        cashManagementDisclosure: 'Cash Management Program Disclosure Statement',
        cashManagementDisclosureBankSweep: 'Cash Management Program Disclosure Statement - Bank Sweep',
        clientRelationshipSummary: 'Client Relationship Summary',
        policiesAndPracticesTitle: 'Policies & Practices',
        fundingAndWithdrawalPolicies: 'Funding & Withdrawal Policies',
        businessContinuityPlan: 'Business Continuity Plan',
        accountSecurityBestPractices: 'Account Security Best Practices',
        cookiePolicy: 'Cookie Policy',
        privacyPolicy: 'Privacy Policy',
        termsOfUse: 'Terms of Use',
        financialStatementsTitle: 'Financial Statements',
        statementOfFinancialConditions: 'Statements of Financial Conditions',
    }
}