import * as React from "react";
import styled from "styled-components";
import { FaPowerOff } from "react-icons/fa";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { services } from "services";
import { useIntl } from "react-intl";

const SignOutContainer = styled.div`
    flex: 1;
    width: 100%;
    color: ${props => props.theme.lightestTextColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
`;

const LogoutBtn = styled.div`
     color: ${props => props.theme.lightestTextColor};
     cursor: pointer;
     margin-left: 13%;
     margin-top: 5%;
     font-size: 1.3em;
     margin-bottom: 5%;
`;

const LinkText = styled.span`
    margin-left: 5%;
`;

const Logout = (props: RouteComponentProps) => {
    const intl = useIntl();
    const signOut = () => {
        services.logout();
        props.history.push("/login");
    }

    return (
        <SignOutContainer>
            <LogoutBtn onClick={signOut}>
                <FaPowerOff />
                <LinkText>{intl.formatMessage({id: "sidebar.logout", defaultMessage: "Logout"})}</LinkText>
            </LogoutBtn>
        </SignOutContainer>
    );
};

export default withRouter(Logout);
