const primaryBase = '#0059ff';
const primary1 = '#0042BE';
const primary2 = '#0053ED';
const primary3 = '#377BFC';
const primary4 = '#6DA0FF';

const brandBase = '#F1B924';

const positiveBase = '#00FF9C';
const positive1 = '#045441';
const positive2 = '#0D9F6B';
const positive3 = '#00D390';
const positive4 = '#86E3C6';

const infoBase = '#2E9BBD';
const info1 = '#125367';
const info2 = '#257C97';
const info3 = '#58AFCA';
const info4 = '#A2E1F4';

const warningBase = '#F1B924';
const warning1 = '#FF7B00';
const warning2 = '#FF9500';
const warning3 = '#FFB700';
const warning4 = '#F5CE66';

const negativeBase = '#FF0A54';
const negative1 = '#990633';
const negative2 = '#DE0949';
const negative3 = '#FF6C99';
const negative4 = '#FFCEDD';

const grapeBase = '#8052FF';
const grape1 = '#4A1E9E';
const grape2 = '#7047BF';
const grape3 = '#BB98FF';
const grape4 = '#E8D5FF';

const ceriseBase = '#DA0C94';
const cerise1 = '#8E025E';
const cerise2 = '#DC2DA0';
const cerise3 = '#FF48C0';
const cerise4 = '#FF8CD8';

const corePrimaryBase = '#1D252E';
const corePrimary1 = '#242C34';
const corePrimary2 = '#2E333D';
const corePrimary3 = '#3E434C';
const corePrimary4 = '#4C5159';
const corePrimary5 = '#A1A3A8';

const white = '#ffffff';

const lightBase = '#E5E8ED';
const light1 = '#F7F9FF';
const light2 = '#F5F6F8';
const light3 = '#D8DCE5';
const light4 = '#C1C7D3';
const light5 = '#757986';

export {
  white,
  primaryBase,
  primary1,
  primary2,
  primary3,
  primary4,
  brandBase,
  positiveBase,
  positive1,
  positive2,
  positive3,
  positive4,
  infoBase,
  info1,
  info2,
  info3,
  info4,
  warningBase,
  warning1,
  warning2,
  warning3,
  warning4,
  negativeBase,
  negative1,
  negative2,
  negative3,
  negative4,
  grapeBase,
  grape1,
  grape2,
  grape3,
  grape4,
  ceriseBase,
  cerise1,
  cerise2,
  cerise3,
  cerise4,
  corePrimaryBase,
  corePrimary1,
  corePrimary2,
  corePrimary3,
  corePrimary4,
  corePrimary5,
  lightBase,
  light1,
  light2,
  light3,
  light4,
  light5,
};
