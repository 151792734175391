import * as React from 'react';
import { ThemeProvider } from "styled-components";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import Loadable from "react-loadable";
import ProtectedRoute from "components/PrivateRoute/PrivateRoute";
import Loader from "components/Loader";
import Layout from 'components/Layout';
import { servicesFactory } from "services";
import { theme } from './defaultTheme';

const Login = Loadable({
  loader: () => import("routes/Login"),
  loading: Loader,
})

const TaxCenterUtility = Loadable({
  loader: () => import("routes/TaxCenterUtility"),
  loading: Loader,
})

const Dash = Loadable({
  loader: () => import("routes/Dashbaord"),
  loading: Loader,
})

const UserInfo = Loadable({
  loader: () => import("routes/UserInfo"),
  loading: Loader,
})

const Statements = Loadable({
  loader: () => import("routes/Statements"),
  loading: Loader,
})

const TradeConfirms = Loadable({
  loader: () => import("routes/TradeConfirms"),
  loading: Loader,
})

const TaxForms = Loadable({
  loader: () => import("routes/Taxforms"),
  loading: Loader,
})

const W8 = Loadable({
  loader: () => import("routes/W8"),
  loading: Loader,
})

const DisclosuresAndRegulatory = Loadable({
  loader: () => import("routes/DisclosureAndRegulatory"),
  loading: Loader,
})

const App = (props: RouteComponentProps) => {
  servicesFactory(props.history);

  return (
    <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/tax-center-util" component={TaxCenterUtility} />
          <Layout>
            <ProtectedRoute exact path="/" component={Dash} />
            <ProtectedRoute path="/disclosure-regulatory" component={DisclosuresAndRegulatory} />
            <ProtectedRoute path="/user-info" component={UserInfo} />
            <ProtectedRoute path="/statements" component={Statements} />
            <ProtectedRoute path="/trade-confirms" component={TradeConfirms} />
            <ProtectedRoute path="/tax-forms" component={TaxForms} />
            <ProtectedRoute path="/w8" component={W8} />
          </Layout>
        </Switch>
    </ThemeProvider>
  );
}

export default withRouter(App);