import * as React from "react";
import styled from "styled-components";
import { services } from "services";
import { CurrentUser } from "services/users";
import Nav from "./Nav";
import Logo from "./Logo";
import { breakPoints } from "consts";
import CurrentSession from "./CurrentSession";
import Logout from "./Logout";
import Cookies from 'js-cookie';

const Page = styled.div`
    @media (${breakPoints.tabletPortraitMax}) {
        display: block;
    }
    display: flex;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.colors.corePrimaryBase};
    font-family: ${props => props.theme.typography.fontFamily};
`;

const Top = styled.div`
    display: flex;
    height: 10vh;
    @media (${breakPoints.tabletLandscapeMax}) {
        width: 100%;
        height: auto;
        min-height: 10vh;
        display: block;
    }
    width: 90%;
`;

const Sidebar = styled.div<{ display?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
    font-size: 16px;
    background-color: ${props => props.theme.colors.corePrimary3};
    @media (${breakPoints.tabletLandscapeMax}) {
        width: ${props => props.display ? "40%" : "0%"};
        visibility: ${props => props.display ? "visible" : "hidden"};
        position: absolute;
        min-height: 100%;
        z-index: 2;
        transition: all .1s linear;
    }
    @media (${breakPoints.tabletPortraitMax}) {
        width: ${props => props.display ? "100%" : "0%"};
        visibility: ${props => props.display ? "visible" : "hidden"};
        position: absolute;
        min-height: 100%;
        z-index: 2;
        transition: all .1s linear;
    }
`;

const Body = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (${breakPoints.tabletLandscapeMax}) {
       width: 100%;
    }
`;

const PagePanel = styled.div`
    display: flex;
    flex: 1;
    background-color: ${props => props.theme.colors.corePrimaryBase};
    color: ${props => props.theme.colors.white};
    width: 90%;
    @media (${breakPoints.tabletLandscapeMax}) {
       width: 100%;
    }
    @media (${breakPoints.tabletPortraitMax}) {
       width: 100%;
       padding-left: 0;
    }
    // border: 1px solid ${props => props.theme.borderColor};
    padding-left: 5%;
    overflow: auto;
`;

export const Content = styled.div`
    width: 100%;
    @media (${breakPoints.tabletPortraitMax}) {
       /* width: 100%; */
       align-items: center;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: ${props => props.theme.typography.fontFamily};
`;

interface Props {
    children?: React.ReactNode;
}

interface CurrentUserContext extends CurrentUser {
    loading: boolean;
    getCurrentUser: () => Promise<{ loading: boolean }>;
    selectAccount: (id: string) => void;
}

const defaultContextValues = {} as CurrentUserContext;
export const SessionContext = React.createContext(defaultContextValues);

const Layout = (props: Props) => {
    const [primaryAccount, setAccount] = React.useState({ id: "", accountNo: "" });
    const [accounts, setAccounts] = React.useState<Array<{ id: string, accountNo: string }>>([]);
    const [profileUpdateRequired, setProfileUpdateRequired] = React.useState(false);
    const [w8VerificationRequired, setW8VerificationRequired] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [showMenu, setShowMenu] = React.useState(false);

    React.useEffect(() => {
        const countries = localStorage.getItem("ambCountries");
        const token = Cookies.get('amb-auth-token');
        if (token) {
            if (!countries) {
                services.getCountries().then(res => {
                    if (res.data) {
                        const countries = res.data.countries.map((c: any) => {
                            return {
                                text: c.name,
                                value: c.code3,
                                key: c.code3,
                            };
                        });
                        localStorage.setItem("ambCountries", JSON.stringify(countries));
                    }
                })
            }
        }
    }, []);

    const selectAccount = (id: string) => {
        const account = accounts.find(a => a.id === id);
        if (account) {
            setAccount(account);
        }
    }

    const getCurrentUser = () => {
        setLoading(true);
        return services.getCurrentUserInfo().then(res => {
            if (res.data) {
                setLoading(false);
                setAccount(res.data.primaryAccount);
                setAccounts(res.data.accounts);
                setProfileUpdateRequired(res.data.profileUpdateRequired);
                setW8VerificationRequired(res.data.w8VerificationRequired);
                setFirstName(res.data.firstName);
                setLastName(res.data.lastName);
                return {
                    loading: false,
                };
            } else {
                return {
                    loading: false,
                }
            }
        })
    }

    React.useEffect(() => {
        const token = Cookies.get('amb-auth-token');
        if (token) {
            getCurrentUser();
        }
    }, []);

    const contextValues = {
        primaryAccount,
        accounts,
        profileUpdateRequired,
        w8VerificationRequired,
        loading,
        firstName,
        lastName,
        getCurrentUser,
        selectAccount,
    };

    return (
        <SessionContext.Provider value={contextValues}>
            <Page>
                <Sidebar display={showMenu}>
                    <Logo hideMenu={() => setShowMenu(false)} />
                    <Nav hideMenu={() => setShowMenu(false)} />
                    <Logout />
                </Sidebar>
                <Body>
                    <Top>
                        <CurrentSession showMenu={() => setShowMenu(true)} />
                    </Top>
                    <PagePanel>
                        {props.children}
                    </PagePanel>
                </Body>
            </Page>
        </SessionContext.Provider>
    );
};

export default Layout;