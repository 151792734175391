import * as React from "react";
import styled from "styled-components";
import { breakPoints } from "consts";
import { SessionContext } from "./";

const Header = styled.span`
    color: ${props => props.theme.colors.white};
    font-size: 16px;
`;

const HeaderWrapper = styled.div`
    margin-left: 5%;
    @media (${breakPoints.tabletLandscapeMax}) {
        display: none;
    }
`;

const CurrentUserContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media (${breakPoints.tabletLandscapeMax}) {
        justify-content: space-between;
        min-height: 10vh;
    }
`;

const Burger = styled.div`
    margin-right: 20px;
    cursor: pointer;
    display: none;
    @media (${breakPoints.tabletLandscapeMax}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const Logo = styled.img`
    height: auto;
    width: 48%;
    display: none;
    margin-top: 2%;
    margin-bottom: 2%;
    @media (${breakPoints.tabletLandscapeMax}) {
        display: inline;
    }
    margin-left: 4%;
`;

const BurgerLine = styled.div`
    @media (${breakPoints.tabletLandscapeMax}) {
        margin-top: 10px;
        height: 5px;
        width: 50px;
    }
    @media (${breakPoints.tabletPortraitMax}) {
        margin-top: 5px;
        height: 4px;
        width: 40px;
    }
    @media (${breakPoints.phoneMax}) {
        margin-top: 5px;
        height: 3px;
        width: 35px;
    }
    background-color: ${props => props.theme.darkTextColor};
`;

interface Props {
    showMenu: () => void
}

const CurrentSession = (props: Props) => {
    const context = React.useContext(SessionContext);
    return (
        <CurrentUserContainer>
            <HeaderWrapper>
                <Header>{context.firstName} {context.lastName}</Header>
            </HeaderWrapper>
            <Logo src="drivewealth-logo.svg" />
            <Burger onClick={props.showMenu}>
                <BurgerLine />
                <BurgerLine />
                <BurgerLine />
            </Burger>
        </CurrentUserContainer>
    );
};

export default CurrentSession;
