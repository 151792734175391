import { request, History, Response } from "./request";
import { User } from "../../../types";

export type docType = "BASIC_INFO" | "ADDRESS_INFO" | "PERSONAL_INFO";

interface Document {
    type: string,
    data: Object,
}

export interface UpdateBody {
    payload: {
        documents: Array<Document>;
    }
    w8Verify: boolean,
    areAllValuesUnchanged?: boolean,
}

interface Account {
    id: string,
    accountNo: string,
}

export interface CurrentUser {
    accounts: Array<Account>,
    primaryAccount: Account,
    profileUpdateRequired: boolean,
    w8VerificationRequired: boolean,
    firstName: string,
    lastName: string,
}

export interface UsersService {
    getUser: () => Promise<Response<User>>;
    updateUser: (body: UpdateBody) => Promise<Response<User>>;
    getCurrentUserInfo: () => Promise<Response<CurrentUser>>
}

function usersFactory(h: History): UsersService {
    const users: UsersService = {
        getUser() {
            return request<User>({
                method: "get",
                endpoint: "/api/users",
            }, h)
        },
        updateUser(body: UpdateBody) {
            return request<User>({
                method: "patch",
                endpoint: "/api/users",
                body,
            }, h)
        },
        getCurrentUserInfo() {
            return request<CurrentUser>({
                method: "get",
                endpoint: "/api/users/me",
            }, h)
        }
    }
    return users;
}

export {
    usersFactory,
}

